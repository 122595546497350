import "bootstrap";


import BusinessP from "../../images/Business.png";
import BusinessG from "../../images/Business.gif";

import DroneP from "../../images/Drone.png";
import DroneG from "../../images/Drone.gif";

import BeatP from "../../images/Beat.png";
import BeatG from "../../images/Beat.gif";

import GemP from "../../images/Gem.png";
import GemG from "../../images/Gem.gif";

import ExploreP from "../../images/Explore.png";
import ExploreG from "../../images/Explore.gif";


$(function () {
  $("#Gem").hover(
    function () {
      $("#GemImg").attr(
        "src",
        GemG
      );
    },
    function () {
      $("#GemImg").attr(
        "src",
        GemP
      );
    }
  );
});

$(function () {
  $("#Explore").hover(
    function () {
      $("#ExploreImg").attr(
        "src",
        ExploreG
      );
    },
    function () {
      $("#ExploreImg").attr(
        "src",
        ExploreP
      );
    }
  );
});

$(function () {
  $("#Beat").hover(
    function () {
      $("#BeatImg").attr(
        "src",
        BeatG
      );
    },
    function () {
      $("#BeatImg").attr(
        "src",
        BeatP
      );
    }
  );
});

$(function () {
  $("#Drone").hover(
    function () {
      $("#DroneImg").attr(
        "src",
        DroneG
      );
    },
    function () {
      $("#DroneImg").attr(
        "src",
        DroneP
      );
    }
  );
});

$(function () {
  $("#Business").hover(
    function () {
      $("#BusinessImg").attr(
        "src",
        BusinessG
      );
    },
    function () {
      $("#BusinessImg").attr(
        "src",
        BusinessP
      );
    }
  );
});
